import React, { useState } from "react";
import "../styles/PortfolioSection.scss";

const PortfolioSection = () => {
  const [expandedRows, setExpandedRows] = useState({});

  const projects = [
    {
      client: "Julija Sadaunykaitė",
      project: "Svetainė",
      industry: "Menas",
      year: 2025,
      details: {
        description: `
          Ši svetainė sukurta siekiant reprezentuoti pianistės Julijos Sadaunykaitės profesinę veiklą ir kūrybinį kelią. Tai vieno puslapio svetainė, kurios tikslas – estetiškai ir funkcionaliai perteikti svarbiausią informaciją apie atlikėją. <br /> 🔹 Biografija: Detalus pianisto karjeros ir pasiekimų aprašymas, aiškiai ir estetiškai pateiktas. <br />
          🔹 Koncertų tvarkaraštis: Patogiai išdėstyta artėjančių koncertų informacija su datomis, vietomis. <br /> 🔹 Vaizdo įrašai: Svetainėje integruoti atlikėjos koncertų vaizdo įrašai iš „YouTube“ kanalo, leidžiantys lankytojams iš karto susipažinti su jos pasirodymais. <br /> 🔹 Muzikos grotuvas: Pridėta galimybė klausytis pasirinkto atlikėjos kūrinio tiesiogiai svetainėje paspaudus vieną mygtuką. <br /> 🔹Kontaktai: Paprastas ir funkcionalus kontaktų skyrius, leidžiantis susisiekti dėl bendradarbiavimo ar užklausų. <br /> 💡 Tai minimalistinis, tačiau funkcionalus asmeninės reprezentacijos projektas, skirtas atlikėjai profesionaliai ir elegantiškai prisistatyti savo auditorijai. <br /> 
        `,
        images: ["/images/julijasada_com.jpg"],
      },
      link: "https://julijasada.com/",
    },
    {
      client: "Vilnius Helps, VšĮ",
      project: "Svetainė",
      industry: "Bendravimas ir verslas",
      year: 2024,
      details: {
        description: `
          Ši interaktivė svetainė – tikras pagalbininkas Vilniuje gyvenantiems ir besilankantiems žmonėms. <br /> 🔹 Svetainė veikia trijose kalbose – lietuvių, anglų ir rusų. <br />
          🔹 Joje rasite patogų kalendorių, kur galite peržiūrėti ir pasirinkti renginius ar paslaugas jums tinkamiausią dieną. <br /> 🔹 Modernus ir patrauklus dizainas, lengvai naršomas. <br /> 🔹 Paprastos ir greitos registracijos formos – viskas, ko reikia jūsų patogumui. <br /> 💡 Kūrėme šį projektą, siekdami sukurti draugišką, šiuolaikišką ir praktišką erdvę kiekvienam vartotojui. <br /> 
        `,
        images: [
          "/images/example2.png",
          "/images/example3.png",
          "/images/example4.png",
          "/images/example5.png",
        ],
      },
      link: "https://www.vilniushelps.lt",
    },
    {
      client: "@baker_street_vilnius",
      project: "Svetainė",
      industry: "Konditerija",
      year: 2024,
      details: {
        description: `
          Ši svetainė buvo sukurta konditerijos verslui, siekiant suteikti lankytojams modernią, patogią ir funkcionalią platformą. <br /> 🔹 Interaktyvus meniu: patogus sprendimas, leidžiantis lankytojams peržiūrėti siūlomus produktus ir paslaugas. <br />
          🔹 Apie mus skiltis: suteikiama galimybė susipažinti su verslo istorija ir vertybėmis. <br /> 🔹 Vaizdo turinys: svetainėje integruotas vaizdo įrašas, kuris padeda geriau atskleisti verslo unikalumą. <br /> 🔹 Dvikalbis dizainas: puslapis prieinamas dviem kalbomis, užtikrinant patogumą platesnei auditorijai. <br /> 🔹 Galerija: aukštos kokybės tortų ir desertų nuotraukos su galimybe peržiūrėti didesniu formatu. <br /> 💡 Mes sukurėmę paprastą, estetišką ir intuityvų dizainą, kuris puikiai perteiktų konditerijos verslo vertybes ir padėtų pritraukti naujus klientus. <br /> 
        `,
        images: [
          "/images/example6.png",
          "/images/example7.png",
          "/images/example8.png",
          "/images/example9.png",
        ],
      },
      link: "https://bkr.lt/",
    },
    {
      client: "Läuft germany",
      project: "Drabužių etiketes",
      industry: "Drabužiai",
      year: 2024,
      details: {
        description:
          "Sukūrėme minimalistinio dizaino drabužių etiketes, kurios išsiskiria stilingumu ir estetiškumu. <br /> 💡 Pagrindinis dėmesys skirtas aukštos kokybės vaizdo reprezentacijai, pabrėžiančiai prekės ženklo išskirtinumą.",
        images: ["/images/lauft.jpg"],
      },
    },
    {
      client: "Restaurant «Positano»",
      project: "Restorano menių",
      industry: "Restoranas",
      year: 2024,
      details: {
        description:
          "Sukūrėme elegantišką ir funkcionalų restorano „Positano“ meniu dizainą, kuris atspindi Viduržemio įkvėpimus. <br /> 💡 Unikalus spalvų ir dekoratyvinių elementų derinys pabrėžia restorano išskirtinumą ir suteikia meniu jaukumo bei estetikos.",
        images: ["/images/positano.jpg"],
      },
    },
    {
      client: "Iron rhino detailing",
      project: "Logotipas ir vizitine kortelė",
      industry: "Automobilių servisas",
      year: 2024,
      details: {
        description:
          "Sukūrėme modernų ir išskirtinį logotipą „Iron Rhino Detailing“ automobilių detalizavimo paslaugų įmonei. <br /> 🔹 Logotipas atspindi tvirtumą, kokybę ir profesionalumą, kurie yra pagrindinės įmonės vertybės. <br /> 💡 Kiekviena detalė buvo kruopščiai parinkta siekiant atspindėti automobilių priežiūros paslaugų preciziškumą ir išskirtinumą.",
        images: ["/images/iron.jpg"],
      },
    },
    {
      client: "@baker_street_vilnius",
      project: "Logotipas ir vizitine kortelė",
      industry: "Konditerija",
      year: 2024,
      details: {
        description:
          "Sukūrėme elegantišką logotipą ir stilingą vizitinės kortelės dizainą „Baker Street Vilnius“ konditerijos verslui. <br /> 🔹 Logotipas atspindi šiltą ir jaukią atmosferą, kurią siūlo konditerija, o vizitinės kortelės dizainas pabrėžia aukštą kokybę ir dėmesį detalėms. <br /> 💡 Šis projektas padeda sukurti solidų ir profesionalų prekės ženklo įvaizdį.",
        images: ["/images/pic_4.jpg", "/images/pic_1.jpg", "/images/pic_6.jpg"],
      },
    },
    {
      client: "Festivalis ABC",
      project: "Pavyzdinė svetainė",
      industry: "Renginiai",
      year: 2023,
      details: {
        description:
          "Sukūrėme šiuolaikišką ir funkcionalią svetainę, skirtą renginiams. Ši svetainė yra patraukli ir lengvai naudojama, padedanti efektyviai komunikuoti su auditorija ir plėsti verslo galimybes.",
        images: ["/images/festival.png"],
      },
    },
    {
      client: "ART-TIMES",
      project: "Pavyzdinė svetainė",
      industry: "Menas",
      year: 2023,
      details: {
        description:
          "Sukūrėme šiuolaikišką ir funkcionalią svetainę, skirtą pristatyti savo kūrinius. Ši svetainė yra patraukli ir lengvai naudojama, padedanti efektyviai komunikuoti su auditorija ir plėsti verslo galimybes.",
        images: ["/images/art-school.png"],
      },
    },
    {
      client: "M.D. konditerija",
      project: "Pavyzdinis logitipas",
      industry: "Konditerija",
      year: 2023,
      details: {
        description:
          "Sukūrėme unikalų logotipą, atspindintį konditerijos vertybes ir identitetą. Šis logotipas padeda formuoti profesionalų ir įsimintiną prekės ženklo įvaizdį.",
        images: ["/images/pic_7.jpg"],
      },
    },
    {
      client: "Portfolio svetainė",
      project: "Reprezentacinė nuotraukų portfolio svetainė",
      industry: "Menas",
      year: 2023,
      details: {
        description:
          "Sukūrėme šiuolaikišką ir funkcionalią svetainę, skirtą pristatyti savo nuotraukų portfolio. Ši svetainė yra patraukli ir lengvai naudojama, padedanti efektyviai komunikuoti su auditorija ir plėsti verslo galimybes.",
        images: ["/images/photo_portfolio.png"],
      },
    },
    {
      client: "Keliauk kartu",
      project: "Pavyzdinė svetainė",
      industry: "Laisvalaikis",
      year: 2023,
      details: {
        description:
          "Sukūrėme šiuolaikišką ir funkcionalią svetainę, skirtą pristatyti Lietuvos lankytinos vietos. Ši svetainė yra patraukli ir lengvai naudojama, padedanti efektyviai komunikuoti su auditorija ir plėsti verslo galimybes.",
        images: ["/images/keliauk_kartu.png"],
      },
    },
    {
      client: "Kelionių svetainė",
      project: "Pavyzdinė svetainė",
      industry: "Kelionės, laisvalaikis",
      year: 2023,
      details: {
        description:
          "Sukūrėme šiuolaikišką ir funkcionalią svetainę, skirtą pristatyti kelinių agenturą. Ši svetainė yra patraukli ir lengvai naudojama, padedanti efektyviai komunikuoti su auditorija ir plėsti verslo galimybes.",
        images: ["/images/travel_agency.png"],
      },
    },
  ];

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <section className="portfolio-section">
      <div className="portfolio-header">
        <h1>Iš viso: {projects.length}</h1>
        <h1>Portfolio</h1>
      </div>
      <table className="portfolio-table">
        <thead>
          <tr>
            <th>Klientas</th>
            <th>Projektas</th>
            <th>Industrija</th>
            <th>Metai</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <>
              <tr
                key={index}
                className={`portfolio-row ${
                  expandedRows[index] ? "expanded" : ""
                }`}
              >
                <td>{project.client}</td>
                <td>{project.project}</td>
                <td>{project.industry}</td>
                <td>{project.year}</td>
                <td>
                  <button
                    className="plus-icon"
                    onClick={() => toggleRow(index)}
                  >
                    {expandedRows[index] ? "-" : "+"}
                  </button>
                </td>
              </tr>
              {expandedRows[index] && (
                <tr className="portfolio-details">
                  <td colSpan="5">
                    <div className="details-content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: project.details.description,
                        }}
                      ></p>
                      {project.link && (
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="project-link"
                        >
                          Pereiti į svetainę
                        </a>
                      )}
                      <div className="details-images">
                        {project.details.images.map((image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={image}
                            alt={`Project ${index + 1} Image ${imgIndex + 1}`}
                          />
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default PortfolioSection;
